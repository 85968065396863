<template>
  <v-card elevation="1">
    <v-data-table
      :headers="headers"
      :items="inspection_templates"
      :items-per-page="10"
      :search="search"
      :header-props="{ sortIcon: null }"
      sort-by="name"
      class="elevation-2"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="page-title">
            <v-icon class="secondary--text" left light>{{ icons.mdiCheckAll }}</v-icon>
            {{ $route.meta.title }}</v-toolbar-title
          >
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-layout>
            <v-spacer></v-spacer>
            <div v-for="(permission, index) in permission" :key="index">
              <div v-for="(usermenu, index1) in permission.usermenus" :key="index1">
                <div v-for="(user_submenu, index2) in usermenu.user_submenus" :key="index2">
                  <v-btn
                    v-show="user_submenu.create == 1 && permission.role_id == 3"
                    color="accent"
                    rounded
                    @click.prevent="$router.push({ name: 'addnewinspectiontemplate' })"
                  >
                    <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
                    <span>Create New </span>
                  </v-btn>
                </div>
              </div>
            </div>
            <div>
              <v-btn
                v-show="currentUser.role_id == 1"
                color="accent"
                rounded
                @click.prevent="$router.push({ name: 'addnewinspectiontemplate' })"
              >
                <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
                <span>Create New </span>
              </v-btn>
            </div>
          </v-layout>
        </v-toolbar>
        <v-divider class="mb-2"></v-divider>
        <v-text-field
          color="accent"
          class="mx-2"
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </template>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(inspectiontemplate, index1) in items" :key="index1">
            <td>{{ inspectiontemplate.name }}</td>
            <td>{{ inspectiontemplate.items }}</td>
            <td>{{ formatDate(inspectiontemplate.created_at) }}</td>
            <td>
              <v-layout class="mx-1">
                <div v-for="(permission, index) in permission" :key="index">
                  <div v-for="(usermenu, index1) in permission.usermenus" :key="index1">
                    <div v-for="(user_submenu, index2) in usermenu.user_submenus" :key="index2">
                      <v-btn
                        v-show="user_submenu.update == 1 && permission.role_id == 3"
                        color="primary"
                        @click.prevent="
                          $router.push({
                            name: 'editnewinspectiontemplate',
                            params: { id: inspectiontemplate.inspection_id },
                          })
                        "
                        fab
                        x-small
                        dark
                      >
                        <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div v-show="currentUser.role_id == 1">
                  <v-btn
                    color="primary"
                    @click.prevent="
                      $router.push({
                        name: 'editnewinspectiontemplate',
                        params: { id: inspectiontemplate.inspection_id },
                      })
                    "
                    fab
                    x-small
                    dark
                  >
                    <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                  </v-btn>
                </div>
                <div>
                  <div v-for="(permission, index) in permission" :key="index">
                    <div v-for="(usermenu, index1) in permission.usermenus" :key="index1">
                      <div v-for="(user_submenu, index2) in usermenu.user_submenus" :key="index2">
                        <v-btn
                          v-show="user_submenu.delete == 1 && permission.role_id == 3"
                          @click.prevent="deleteInspectionTemplate(inspectiontemplate.inspection_id)"
                          color="error"
                          fab
                          x-small
                          dark
                          class="mx-1"
                        >
                          <v-icon>{{ icons.mdiDeleteForeverOutline }}</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                  <div>
                    <v-btn
                      v-show="currentUser.role_id == 1"
                      @click.prevent="deleteInspectionTemplate(inspectiontemplate.inspection_id)"
                      color="error"
                      fab
                      x-small
                      dark
                      class="mx-1"
                    >
                      <v-icon>{{ icons.mdiDeleteForeverOutline }}</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-layout>
            </td>
          </tr>
        </tbody>
      </template>
      <template v-slot:no-data>
        <v-btn color="accent" @click="getInspectionTemplates"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {
  mdiAccountTie,
  mdiMagnify,
  mdiPencil,
  mdiDotsVertical,
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteForeverOutline,
  mdiChartBoxOutline,
  mdiCheckAll,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  components: {},

  created: function () {
    this.getInspectionTemplates()
    this.getAuthorizations()
  },

  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        { text: 'Items', value: 'items' },
        { text: 'Created At', value: 'created_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],

      inspection_templates: [],
      inspection_template: {},
      permission: {},
      search: '',

      icons: {
        mdiAccountTie,
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteForeverOutline,
        mdiMagnify,
        mdiDotsVertical,
        mdiPencil,
        mdiChartBoxOutline,
        mdiCheckAll,
      },
    }
  },

  methods: {
    getInspectionTemplates: function () {
      this.$store
        .dispatch('inspectionTemplate/fetchInspectionTemplates')
        .then(response => {
          this.inspection_templates = this.fetchInspectionTemplates
        })
        .catch(err => {
          console.log(err)
        })
    },

    getAuthorizations: function () {
      this.$store
        .dispatch('authorization/fetchModules', {
          user_id: this.currentUser.user_id,
          menu_id: 7,
          submenu_id: 8,
        })
        .then(response => {
          this.permission = this.fetchAuthorizations
        })
        .catch(err => {
          console.log(err)
        })
    },
    deleteInspectionTemplate: function (id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$swal.fire({
              title: 'Deleted!',
              text: 'The record has been deleted.',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            })
            this.$store
              .dispatch(`inspectionTemplate/deleteInspection`, {
                inspection_id: id,
              })
              .then(response => {
                this.getInspectionTemplates()
              })
          }
        })
    },

    formatDate(value) {
      return moment(value).format('l')
    },
  },

  computed: {
    ...mapGetters({
      fetchInspectionTemplates: 'inspectionTemplate/fetchInspectionTemplates',
      fetchAuthorizations: 'authorization/fetchAuthorizations',
    }),
    currentUser() {
      return this.$store.getters['authentication/currentUser']
    },
  },
}
</script>

<style>
</style>